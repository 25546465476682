















































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { appName, merchantURL, apiUrl } from "@/env";
import VuetifyGoogleAutocomplete from "../VuetifyGoogleAutocomplete.vue";
import {
  dispatchSplynxToken,
  dispatchGetReceipts,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
  dispatchPostFreeManualBill,
  dispatchAutoRenew,
  dispatchSendRefer,
  dispatchSendReferralReminder,
  dispatchRenewalSetting,
} from "@/store/main/actions";
import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";
import moment, { duration } from "moment-timezone";
import _ from "lodash";
import Loading from "../Loading.vue";
import { formatAddress } from "../../utils";
import PayNow from "./PayNow.vue";
import Plans from "../Plans.vue";

@Component({
  components: { Loading, VuetifyGoogleAutocomplete, PayNow, Plans },
})
export default class Dashboard extends Vue {
  public appName = appName;
  public dialogMini = false;
  public dialogPaid = false;
  public dialogRefer = false;
  public dialogUpgrade = false;
  public dialogUpgradeComplete = false;

  public merchantURL = merchantURL;
  public showLoading = false;
  public data = {};
  public receipts = [];
  public soa = [];
  public invoices = [];
  public token = "";
  public currentSoa = {};
  public prevSoa = {};
  public needsPayment = false;
  public ip = "";
  public receiptHeaders = [
    {
      text: "Plan",
      sortable: true,
      value: "plan_name",
      align: "left",
    },
    {
      text: "Payment Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Amount",
      sortable: true,
      value: "TranAmt",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },

    {
      text: "Receipt ID",
      sortable: true,
      value: "TranID",
      align: "left",
    },
  ];

  public invoiceHeaders = [
    {
      text: "Plan",
      sortable: true,
      value: "plan_name",
      align: "left",
    },
    {
      text: "Invoice Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Amount",
      sortable: true,
      value: "TranAmt",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },
    {
      text: "Invoice ID",
      sortable: true,
      value: "BillingRef",
      align: "left",
    },
  ];

  public soaHeaders = [
    {
      text: "Plan",
      sortable: true,
      value: "plan_name",
      align: "left",
    },
    {
      text: "SOA Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Previous Balance",
      sortable: true,
      value: "PreviousBalance",
      align: "left",
    },
    {
      text: "Current Balance",
      sortable: true,
      value: "CurrentBalance",
      align: "left",
    },
    {
      text: "Total Amount Due",
      sortable: true,
      value: "TotalAmt",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },
    {
      text: "SOA ID",
      sortable: true,
      value: "SOANo",
      align: "left",
    },
  ];
  public rebateHeaders = [
    {
      text: "Rebate ID",
      sortable: true,
      value: "id",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Amount",
      sortable: true,
      value: "amount",
      align: "left",
    },
    {
      text: "Remaining Amount",
      sortable: true,
      value: "remaining_amount",
      align: "left",
    },
    {
      text: "Note",
      sortable: true,
      value: "comment",
      align: "left",
    },
  ];
  public plans = [];
  public payments = [];
  public services = [];
  public referred_people = [];
  public last_paid = [];
  public rebates = [];
  public is_auto_renew = true;
  public referrals = "";
  public referral_msg = "";
  public renewal_plan = 1;
  public ptype = "CC";
  $refs!: Vue["$refs"] & {
    form: HTMLFormElement;
    csrf: HTMLFormElement;
    session: HTMLFormElement;
  };

  mounted() {
    if (!_.get(this.userProfile, "is_superuser", false)) {
      this.is_auto_renew = _.get(this.userProfile, "is_auto_renew", true);
      const service_id = _.get(this.userProfile, "service_id", 1);
      dispatchGetReceipts(this.$store, (data) => {
        this.renewal_plan = _.get(data, "data.renewal_id", null) || service_id;
        this.needsPayment = _.get(data, "data.needs_payment", false);
        this.invoices = Object.values(
          _.get(data, "data.invoices", []).reduce((accumulator, item) => {
            if (!(item.BillingRef in accumulator)) {
              accumulator[item.BillingRef] = item;
              const month = moment(
                `${item.year}-${item.month}-01 00:00:00`
              ).format("MMM");
              item["BillingType"] = `${month} Invoice`;
            } else {
              accumulator[item.BillingRef].TranAmt += item.TranAmt;
            }
            return accumulator;
          }, {})
        );
        this.soa = _.get(data, "data.soa", []);
        this.receipts = _.get(data, "data.receipts", []);
        if (this.soa.length) {
          this.currentSoa = _.get(this.soa, "[0]", {});
          this.prevSoa = _.get(this.soa, "[1]", {});
        }
        this.token = _.get(data, "data.token", "");
        this.data = data;
        this.ip = _.get(data, "data.ip", "");
        this.payments = _.get(data, "data.payments", []);
        this.last_paid = _.get(data, "data.last_paid", []);
        this.rebates = _.get(data, "data.rebates", []);
        this.ptype = _.get(data, "data.latest_payment.ptype", "CC");
        this.referred_people = _.get(data, "data.referrals", []);
        this.services = _.get(data, "data.services", []);
        this.plans = this.services.map((service) => {
          return {
            text: service.title,
            value: service.id,
          };
        });
      });
    }
  }

  upgrade_disabled() {
    const not_upgradable = _.get(
      this.userProfile,
      "is_upgrade_disabled",
      false
    );
    const upgrade_disabled_till = _.get(
      this.userProfile,
      "upgrade_disabled_till",
      null
    );
    if (
      not_upgradable &&
      upgrade_disabled_till &&
      moment() < moment(upgrade_disabled_till)
    ) {
      return true;
    }
    return false;
  }

  contract_expired() {
    return !_.get(this.userProfile, "is_contract_active", true);
  }
  changeRenewalPlan(value) {
    dispatchRenewalSetting(this.$store, { renewal_id: value });
  }
  isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }
  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY");
    }
    return "";
  }
  public async loginForm() {
    this.showLoading = true;
    await dispatchSplynxToken(this.$store, {}).then((response) => {
      var data = _.get(response, "data.data", {});
      this.$refs.csrf.value = data.csrf_token;
      this.$refs.session.value = data.session_id;

      this.$refs.form.submit();
    });
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.first_name) {
        return userProfile.first_name + " " + userProfile.last_name;
      } else {
        return userProfile.email;
      }
    }
  }

  public closePaidDialog() {
    this.dialogPaid = false;
    this.dialogMini = false;
  }

  public showPayNow() {
    const amt = _.get(this.currentSoa, "TotalAmt", {});
    //if (!this.needsPayment) {
    //  this.dialogPaid = true;
    //} else {
    this.dialogMini = true;
    //}
  }

  getPDFLink(type, doc_id) {
    const user_id = _.get(this.userProfile, "id", "");
    const token = this.token || "";
    return `/api/v1/users/pdf/${user_id}/${type}/${doc_id}/${token}/`;
  }

  public getItems(item) {
    return _.get(item, "splynx_items_json.items", []);
  }

  public getContractDate() {
    const date = _.get(this.userProfile, "activation_date", null);
    const length = _.get(this.userProfile, "contract_length", 12);
    return this.formatDateOnly(
      moment(date).add(length, "months").subtract(1, "day")
    );
  }

  public autoRenew() {
    dispatchAutoRenew(this.$store, {
      is_auto_renew: this.is_auto_renew,
    });
  }

  referLink() {
    const cid = _.get(this.userProfile, "splynx_cid", "");
    const link = `${apiUrl}/apply?referral_cid=${cid}`;
    return link;
  }

  closeReferDialog() {
    this.dialogRefer = false;
  }

  showRefer() {
    this.dialogRefer = true;
  }
  sendReferral() {
    dispatchSendRefer(this.$store, {
      referrals: this.referrals,
      referral_msg: this.referral_msg,
    });
    this.dialogRefer = false;
  }

  sendReferralReminder() {
    dispatchSendReferralReminder(this.$store, {});
    this.dialogRefer = false;
  }

  closeUpgradeCompleteDialog() {
    this.dialogUpgradeComplete = false;
    location.reload();
  }
  closeUpgradeDialog(completeshown = false) {
    this.dialogUpgrade = false;
    if (!completeshown) {
      this.dialogUpgradeComplete = true;
    }
  }
  showUpgradeDialog() {
    this.dialogUpgrade = true;
  }
}
