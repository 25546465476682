










































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { appName, merchantURL } from "@/env";
import VuetifyGoogleAutocomplete from "../VuetifyGoogleAutocomplete.vue";
import {
  dispatchSplynxToken,
  dispatchGetReceipts,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
  dispatchPostFreeManualBill,
  dispatchGetPromos,
  dispatchGetBilling,
  dispatchPayNow,
} from "@/store/main/actions";
import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";
import moment from "moment-timezone";
import _ from "lodash";
import Loading from "../Loading.vue";
import { formatAddress } from "../../utils";

@Component({
  components: { Loading, VuetifyGoogleAutocomplete },
  props: {
    onClose: Function,
  },
})
export default class PayNow extends Vue {
  public appName = appName;
  public dialogMini = false;
  public dialogPaid = false;

  public loadingMessage = "Loading Payment Info...";
  public merchantURL = merchantURL;
  public showLoading = false;
  public isPaid = false;
  public data = {};
  public receipts = [];
  public soa = [];
  public invoices = [];
  public token = "";
  public currentSoa = {};
  public prevSoa = {};
  public needsPayment = false;
  public ip = "";
  public pmethod = null;
  public address = "";
  public promoCode = "";
  public promos = [];
  public promo: any = null;
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = "";
  public billingAddress = "";
  public addressHint = "";
  public payments = [
    {
      price: 0,
      splynx_items_json: [],
    },
  ];
  public selectPayment = [true];

  $refs!: Vue["$refs"] & {
    form: HTMLFormElement;
    csrf: HTMLFormElement;
    map: typeof VuetifyGoogleAutocomplete;

    session: HTMLFormElement;
    paymentrequest: HTMLFormElement;
    payment: HTMLFormElement;
  };

  mounted() {
    if (!_.get(this.userProfile, "is_superuser", false)) {
      this.getBilling();
    }
  }

  public async getBilling() {
    this.loadingMessage = "Loading Payment Information...";
    this.showLoading = true;
    const response = await dispatchGetBilling(this.$store, {
      pmethod: this.pmethod || "GC",
    });
    const data = _.get(response, "data", {});
    this.billingAddress = _.get(data, "billing_address.full_address", "");
    this.address = this.billingAddress;
    this.payments = _.get(data, "payments", []);
    this.selectPayment = this.payments.map((payment) => true);
    setTimeout(() => {
      this.showLoading = false;
      this.$refs.map.update(this.billingAddress);
    }, 100);
  }

  public getTotal() {
    return (
      this.payments
        //.filter((payment, index) => index == 0 || this.selectPayment[index])
        .map((payment, index) => payment.price)
        .reduce((total, num) => Math.round(Number(total) + Number(num)), 0)
    );
  }
  public close() {
    this.$props.onClose();
  }
  public getItems(item) {
    return _.get(item, "splynx_items_json.items", []);
  }
  public async getPromo() {
    const callback = (data) => {
      this.promos = _.get(data, "data.promos", []);
      // this.updatePrice();
    };
    const payload = {
      code: this.promoCode,
      callback: callback,
    };
    await dispatchGetPromos(this.$store, payload);
  }
  isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }
  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm");
    }
    return "";
  }
  public async loginForm() {
    this.showLoading = true;
    await dispatchSplynxToken(this.$store, {}).then((response) => {
      var data = _.get(response, "data.data", {});
      this.$refs.csrf.value = data.csrf_token;
      this.$refs.session.value = data.session_id;

      this.$refs.form.submit();
    });
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.first_name) {
        return userProfile.first_name + " " + userProfile.last_name;
      } else {
        return userProfile.email;
      }
    }
  }

  public closePaidDialog() {
    this.dialogPaid = false;
  }

  public validate() {
    if (this.address) {
      const addresses = this.address.split(", ");
      if (addresses.length < 3) {
        this.addressHint =
          "Please select your address from the address selection menu";
      } else {
        this.addressHint = "";
      }
    }
    //return !this.addressHint && !!this.addressData && !!this.pmethod;
    return !this.addressHint && !!this.pmethod;
  }

  public payLink() {
    this.showLoading = true;
    this.loadingMessage = "Sending Payment Information...";
    var response = dispatchPayNow(this.$store, {
      rebill: 1,
      address: this.address,
      addressData: this.addressData
        ? `${JSON.stringify(this.addressData)}`
        : "",
      placeData: this.place ? `${JSON.stringify(this.place)}` : "",
      placeId: this.placeId ? `${JSON.stringify(this.placeId)}` : "",
      ip: this.ip,
      pmethod: this.pmethod,
      serviceId: "",
      routerId: "",
      routerpmethod: "",
      promoCode: _.get(this.promo, "code", ""),
      invoices: this.payments
        //.filter((payment, index) => index === 0 || this.selectPayment[index])
        .map((payment) => ({
          splynx_invoice_id: _.get(payment, "splynx_invoice_id", null),
          period_from: _.get(payment, "period_from", null),
          period_to: _.get(payment, "period_to", null),
        })),
    }).then((response) => {
      const data = _.get(response, "data.data.payload", "");
      const amount = parseFloat(_.get(response, "data.data.amount", 1));
      const request_id = _.get(response, "data.data.request_id", 1);
      if (amount == 0 && request_id) {
        dispatchPostFreeManualBill(this.$store, {
          request_id: request_id,
        }).then((response) => {
          const redir = _.get(response, "data.data.redirect", "");
          location.href = redir;
        });
      } else {
        // document.getElementById("paymentrequest").value = data;
        // document.getElementById("payment").submit();
        this.$refs.paymentrequest.value = data;
        this.$refs.payment.submit();
      }
    });
  }

  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
  }
  public updateAddress(newAddress) {
    this.address = newAddress;
  }

  public pmethodChanged(pm) {
    let is_cc = false;
    let is_gc = false;
    if (pm === "GC") {
      is_cc = false;
      is_gc = true;
    } else {
      is_cc = true;
      is_gc = false;
    }
    this.getBilling();

    this.$refs.map.update(this.billingAddress);
  }
}
